/* eslint-disable react/forbid-prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../Layout'
import MicroFrontend from '../components/MicroFrontend'
import { useIdentity } from '../providers/identityContext'
import handlePageContext from '../utils/handlePageContext'
import getThemeConfig from '../providers/config/themeConfig'

function PlanManager({ data, location, pageContext }) {
  const { user, loggedIn, login, logout } = useIdentity()

  const {
    footer: { type },
    navigationHomeLink,
  } = getThemeConfig()

  const { footer, footerNav, header, headerAuth } = data

  const headerAuthProps = {
    ...headerAuth,
    homeLink: navigationHomeLink,
  }

  const getHeaderProps = () => {
    if (user?.loading) {
      return <></>
    }
    return loggedIn && headerAuth ? headerAuthProps : header
  }

  const headerProps = getHeaderProps()
  const footerProps = type === 'menu' ? footerNav : footer

  const layoutProps = {
    header: headerProps,
    footer: footerProps,
  }

  let microFrontendProps = {
    ...data.contentfulMicroFrontend,
    config: {
      actions: { login, logout },
      location,
      user: {
        loggedIn,
        ...user,
      },
    },
  }

  // Handle if there are .env front end props
  microFrontendProps = handlePageContext(microFrontendProps, pageContext)

  return (
    <Layout {...layoutProps}>
      <MicroFrontend {...microFrontendProps} />
    </Layout>
  )
}

export const planManagerQuery = graphql`
  query PlanManagerPage($mfInstanceName: String) {
    header: contentfulNav(instanceName: { eq: "Main Menu" }) {
      ...navQuery
    }
    headerAuth: contentfulNav(instanceName: { eq: "Main Menu Auth" }) {
      ...navQuery
    }
    footer: contentfulFooter(instanceName: { eq: "Main Footer" }) {
      ...footerQuery
    }
    footerNav: contentfulFooter(instanceName: { eq: "Footer" }) {
      ...footerQuery
    }
    contentfulMicroFrontend(instanceName: { eq: $mfInstanceName }) {
      instanceName
      name
      jsSrc
      cssSrc
    }
  }
`

PlanManager.propTypes = {
  data: PropTypes.shape({
    contentfulHeader: PropTypes.object,
    contentfulFooter: PropTypes.object,
    contentfulMicroFrontend: PropTypes.object,
    footer: PropTypes.object,
    footerNav: PropTypes.object,
    header: PropTypes.object,
    headerAuth: PropTypes.object,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    hostname: PropTypes.string,
  }).isRequired,
  pageContext: PropTypes.shape({
    jsSrc: PropTypes.string,
    cssSrc: PropTypes.string,
  }).isRequired,
}

export default PlanManager
